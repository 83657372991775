/* Base HTML */
:root {
  --brand-color: #3498db
}

html, body {
  width: 100%;
  padding: 0px;
  margin: 0px;
  max-width: initial;
}

main {
  max-width: 850px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
}

/* Reusable Components */

.loading-spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid var(--brand-color); /* Blue */
  border-radius: 50%;
  width: 75px;
  height: 75px;
  margin-left: auto;
  margin-right: auto;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-icon {
  font-size: 125px;
}

/** From: https://www.w3schools.com/howto/howto_css_switch.asp  */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--brand-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--brand-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Element Styles */

.full-screen-message {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.full-screen-message__container {
  text-align: center;
  max-width: 850px;
}

.info-bar {
  position: fixed;
  z-index: 3;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;

  font-size: 0.75rem;
  font-weight: bold;
  background-color: var(--brand-color);
  color: #FFF;
  background-color: var(--brand-color);
}

.info-bar #expire-clock {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  width: 100%;
}

.info-bar #expire-clock div {
  margin-left: 2px;
  margin-right: 2px;
}

.info-bar--spacer {
  height: 20px;
}

.offer {
  max-width: 850px;
}

.offer__header {
    text-align: center;
}

.offer__image {
  width: 100%;
  max-width: 500px;
}

.offer__pyop-select {
  text-align: center;
}

.offer__pyop-select select {
  text-align: center;
  width: 100%;
  border: 2px solid #000;
}


.offer__bump-offer {
  padding: 10px;
  border: 5px dashed var(--brand-color);
  border-radius: 5px;
  margin-bottom: 10px;
}

.offer__bump-offer__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.offer__bump-offer__header__left {
  width: 100%;
  max-width: 300px;
}

.offer__bump-offer__header__image {
  margin-bottom: 10px;
}

.offer__bump-offer__header__right {
  min-width: 300px;
}

.offer__bump-offer__header__title-toggle {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.offer__bump-offer__header__title-toggle > * {
  margin: 0px;
}

.offer__bump-offer__header__title-toggle__toggle {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
}

.offer__bump-offer__header__title-toggle__toggle svg {
  margin-right: 20px;
  fill: var(--brand-color);
  animation: blink 1.5s linear infinite;
  transform: scale(2);
}

@keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}


.offer__bump-offer__header__right h4 {
  margin: 0;
  margin-top: 5px;
}

.offer-controls {
  text-align: center;
}

.offer-controls__variant-select {
  font-weight: bold;
  font-size: 1.15rem;
}

.offer-controls__variant-select label {
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
}

.offer-controls__variant-select select {
  max-width: 100%;
  text-align: center;
  margin-top: 5px;
  display: inline-block;
  font-weight: normal;
}

.offer-controls__variant-select__radio-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.offer-controls__variant-select__radio-container__variant {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 3px solid var(--brand-color);
  border-radius: 5px;
  margin: 10px;
}

.offer-controls__variant-select__radio-container__variant input[type="radio"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 0;
  height: 0;
  cursor: pointer;
}

.offer-controls__variant-select__radio-container__variant label {
  display: inline-block;
  padding: 10px;
  margin: 0;
  cursor: pointer;
}

.offer-controls__variant-select__radio-container__variant input[type="radio"]:checked + label {
  color: #fff;
  background-color: var(--brand-color);
}

.offer-controls__discount-info {
  font-size: 1.15rem;
}

.offer-controls__checkout-button {
  width: 100%;
  font-size: 1.5rem;
  font-weight: bold;
  color: #fafafa;
  background: #00ab66;
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.offer-controls__checkout-button:hover {
  background: #008952;
}

.offer-controls__checkout-button--back {
  font-size: 0.85rem;
  font-weight: bold;
  color: #000;
  background: transparent;
  text-decoration: underline;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.offer-controls__checkout-button--back:hover {
  opacity: 0.75;
  background: transparent;
}

.cart-total {
  text-align: left;
  margin-right: auto;
  width: 100%;
  max-width: 425px;
}

.cart-total__discount-code {
  font-size: 14px;
}

.site-info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  font-size: 14px;
}
